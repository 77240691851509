import { Controller } from "stimulus"

export default class extends Controller {
  static values = {
    stripeKey: String,
    clientSecret: String,
    confirmUrl: String
  }

  connect() {
    const _this = this;
    const submitBtn = document.querySelector('#payment-submit-button');
    const stripe = Stripe(this.stripeKeyValue);

    (async () => {
      const clientSecret = this.clientSecretValue;

      console.log(`Using Secret: ${clientSecret}`)
      // STEP 1: Setup Stripe Form regardless.
      const options = {
        clientSecret: clientSecret,
        appearance: {
          variables: {
            colorPrimary: '#134373',
            colorDanger: '#f2545b',
            fontFamily: 'Helvetica, Arial, sans-serif'
          }
        }
      };

      const elements = stripe.elements(options);

      // Create and mount the Payment Element
      const paymentElement = elements.create('payment');
      paymentElement.mount('#payment-element');

      // STEP 2 - setup a form click handler to process an existing payment method or a new payment method.
      const form = document.getElementById('payment-form');

      const handleError = (error) => {
        const messageContainer = document.querySelector('#error-message');
        messageContainer.textContent = error.message;
        submitBtn.disabled = false;
      }
      
      form.addEventListener('submit', async (e) => {
        e.preventDefault();

        // Prevent multiple form submissions
        if (submitBtn.disabled) {
          return;
        }

        // Disable form submission while loading
        submitBtn.disabled = true;

        let payment_method_identifier = null;
        var ele = document.getElementsByName('payment_method[stripe_identifier]');
        for (let i = 0; i < ele.length; i++) {
          if (ele[i].checked) { payment_method_identifier = ele[i].value }
        }

        console.log(`USING PAYMENT METHOD: ${payment_method_identifier}`)

        if( payment_method_identifier ) {
          // Confirm the PaymentIntent using the details collected by the Payment Element
          const {error} = await stripe.confirmPayment({
            clientSecret,
            confirmParams: {
              return_url: this.confirmUrlValue,
              payment_method: payment_method_identifier
            },
          });

          if (error) {
            // This point is only reached if there's an immediate error when
            // confirming the payment. Show the error to your customer (for example, payment details incomplete)
            handleError(error);
          } else {
            // Your customer is redirected to your `return_url`. For some payment
            // methods like iDEAL, your customer is redirected to an intermediate
            // site first to authorize the payment, then redirected to the `return_url`.
          }
        }
        else {
          // Trigger form validation and wallet collection
          const {error: submitError} = await elements.submit();
          if (submitError) {
            handleError(submitError);
            return;
          }
          // Confirm the PaymentIntent using the details collected by the Payment Element
          const {error} = await stripe.confirmPayment({
            elements,
            confirmParams: {
              return_url: 'https://example.com/order/123/complete',
            },
          });

          if (error) {
            // This point is only reached if there's an immediate error when
            // confirming the payment. Show the error to your customer (for example, payment details incomplete)
            handleError(error);
          } else {
            // Your customer is redirected to your `return_url`. For some payment
            // methods like iDEAL, your customer is redirected to an intermediate
            // site first to authorize the payment, then redirected to the `return_url`.
          }
        }



        // DO THINGS IF THE FORM IS SUBMITTED
      });
    })();


  }
}
