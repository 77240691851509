$(document).on("turbolinks:load", function () {
  $(".service-plan-scheduler").on(
    "click",
    ".services-body .repeat .badge",
    function (event) {
      $(this).toggleClass("badge-primary");
      $(this).toggleClass("badge-light");
    }
  );

  $(".service-plan-scheduler input[type='submit']").on("click", function (e) {
    $("[id$=_recurrence_repeat_on]").each(function () {
      let selected_days = $(this).parent().find(".badge.badge-primary");
      const repeat_on = [];
      if ($(this).parent().is(":visible")) {
        $(selected_days).each(function (i, badge) {
          repeat_on.push($(badge).data().index);
        });
      }
      $(this).val(repeat_on);
    });
  });

  $(
    "#service_subscription_recurring_plan_id, #service_subscriptions_recurring_plan_id, .service_subscription_billing_type"
  ).on("change", function (e) {
    $("[id$=_destroy]").each(function () {
      $(this).attr("value", 1);
    });
    var selected_plan_id = $(
      "#service_subscription_recurring_plan_id, #service_subscriptions_recurring_plan_id"
    ).val();

    if (!!selected_plan_id) {
      Rails.ajax({
        url: `/recurring_plans/${selected_plan_id}/services`,
        type: "POST",
        data: $("form.simple_form").serialize(),
        // success: function(data) {
        //   console.log('success')
        // },
        // error: function() {
        //   console.log('Something went wrong.')
        // }
      });
    }
  });
});

$(document).bind("turbolinks:before-cache", function () {
  $(document).on(
    "click",
    ".service-plan-scheduler .weekly-select .badge",
    function () {
      $(this).toggleClass("badge-primary");
      $(this).toggleClass("badge-light");
    }
  );
});
