$(document).on("turbolinks:load", function () {
  (function ($) {
    document
      .querySelectorAll(".multiselect-label")
      .forEach(function (multiselectLabel) {
        multiselectLabel.addEventListener("click", function (event) {
          document
            .querySelector(
              `[${multiselectLabel.dataset.multiselectDropdownTarget}]`
            )
            .classList.toggle("open");
        });
      });

    document.addEventListener("click", function (event) {
      const multiselectWrappers = document.querySelectorAll(
        ".multiselect-wrapper"
      );

      multiselectWrappers.forEach(function (wrapper) {
        const multiselectDropdown = wrapper.querySelector(
          ".multiselect-dropdown"
        );

        if (wrapper && !wrapper.contains(event.target)) {
          if (multiselectDropdown) {
            multiselectDropdown.classList.remove("open");
          }
        }
      });
    });

    document
      .querySelectorAll(".multiselect-checkbox")
      .forEach(function (checkbox) {
        checkbox.addEventListener("change", function (event) {
          const selectElement = document.querySelector(
            `[${checkbox.dataset.multiselectTarget}]`
          );

          Array.from(selectElement.options).forEach(function (option) {
            if (checkbox.value == option.value) {
              option.selected = checkbox.checked;
              selectElement.dispatchEvent(new Event("change"));
            }
          });
        });
      });
  })(window.jQuery);
});
